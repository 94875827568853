//= require spree
//= require spree/frontend/checkout
//= require spree/frontend/product
//= require spree/frontend/cart
//= require spree/frontend/locale_selector

// require("src/spree.js.erb")
import 'jquery.payment';
import flatpickr from "flatpickr";
import { Italian } from "flatpickr/dist/l10n/it"
import "select2";

require("frontend/checkout");
require("frontend/product");
require("frontend/cart");

require("src/pazzesco/start.js")
require("src/pazzesco/cart.js")
require("src/pazzesco/miscellneous.js")
