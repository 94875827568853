const { Italian } = require("flatpickr/dist/l10n/it");
const MINIMUM_LEAD_TIME_HOURS = 24;

const SMALL_EVENT_LEAD_TIME_DAYS = 3;
const MEDIUM_EVENT_LEAD_TIME_DAYS = 7;
const BIG_EVENT_LEAD_TIME_DAYS = 10;
const MAJOR_EVENT_LEAD_TIME_DAYS = 14;

const MEDIUM_EVENT = 40
const BIG_EVENT = 80
const MAJOR_EVENT = 250

function getDateWidget() {
  return $('#input-date')[0]._flatpickr;
}
window.getDateWidget = getDateWidget;

// Reset field values for zip and date
// function resetZipAndDate() {
//   // console.log('resetZipAndDate');

//   // zip
//   $("#input-zip").each(function () { //added a each loop here
//     $(this).select2('val', '')
//   });

//   // date
//   resetAndDisableDateField();
// };

// Reset date and disable field
function resetAndDisableDateField() {
  // console.log('disableDateField');
  const dateWidget = getDateWidget();
  dateWidget.clear();
  dateWidget._input.disabled = true;
};

// Checks requirements and updates date field with minDate param
function possiblyEnableDateField() {
  // console.log('possiblyEnableDateField');
  var zip = $("#input-zip").val();
  var pax = $("#input-people").val();
  if (zip !== '' && pax !== '') {
    enableDateField(zip, pax);
  }
};

// Enable date field with minDate param
function enableDateField(zip, pax) {
  // console.log('enableDateField');
  const dateWidget = getDateWidget();
  dateWidget._input.disabled = false;

  // const date = new Date();
  // firstAvailability = new Date(date / 1 + 1000 * 3600 * MINIMUM_LEAD_TIME_HOURS);

  zip = zip || $('#input-zip').val();
  pax = pax || $('#input-people').val();
  firstAvailability = firstAvailableDate(zip, pax);
  $("#input-date").flatpickr({
    "dateFormat": "Y-m-j",
    "altInput": true,
    "altFormat": "j M Y",
    "disableMobile": "true",
    // "defaultDate": firstAvailability,
    "minDate": firstAvailability,
    "locale": Italian // locale for this instance only
  });
};

function canDeliverTo(zip_name) {
  const zips = $("#available-zips").data('zips');
  for (var i = 0; i < zips.length; i++) {
    const zip = zips[i];
    if (zip.id === zip_name && zip.text.includes('🚚')) return true;
  }
  return false;
}

function firstAvailableDate(zip, pax) {
  if (canDeliverTo(zip)) {
    // If it is not too late, allow to order today
    // let lastOrderAt = parseInt($('#last-order-at').data('last_order_at'));
    let canDeliverToday = $('#can-deliver-today').data('can-deliver-today') === true;
    if (canDeliverToday) {
      return new Date();
    }
    return new Date(new Date() / 1 + 1000 * 3600 * MINIMUM_LEAD_TIME_HOURS);
  }
  let eventLeadTime;
  if (pax >= MAJOR_EVENT) eventLeadTime = MAJOR_EVENT_LEAD_TIME_DAYS;
  else if (pax >= BIG_EVENT) eventLeadTime = BIG_EVENT_LEAD_TIME_DAYS;
  else if (pax >= MEDIUM_EVENT) eventLeadTime = MEDIUM_EVENT_LEAD_TIME_DAYS;
  else eventLeadTime = SMALL_EVENT_LEAD_TIME_DAYS;
  return new Date(new Date() / 1 + 1000 * 3600 * 24 * eventLeadTime);
}

// // Check if delivery/execution would be to early
// function isDateOld(date) {
//   console.log('isDateOld');
// };

// // Check whether ZIP is selected or in session
// function isZipSelected(date) {
//   console.log('isZipSelected');
// };


// RUN ON START PAGE ONLY
$(function () {
  if (location.pathname !== "/") return;

  const zip = $('#input-zip').val();
  const pax = $('#input-people').val();
  firstAvailability = firstAvailableDate(zip, pax);
  $("#input-date").flatpickr({
    "dateFormat": "Y-m-j",
    "altInput": true,
    "altFormat": "j M Y",
    // "defaultDate": firstAvailability,
    "minDate": firstAvailability,
    "locale": Italian // locale for this instance only
  });
  $(".flatpickr-month").click(function (e) {
    // var months = $(".flatpickr-current-month")
    // alert(months.length)
    $(".flatpickr-month .slideLeft").hide().remove();
    $(".flatpickr-month .slideRight").remove();
  })

  // start page people number
  $("#input-people").val($("#vars").data('people'));

  // start page ZIP selector
  $("#input-zip").select2({
    data: $("#available-zips").data('zips'),
    minimumInputLength: 2,
    formatInputTooShort: function (input, min) { var n = min - input.length; return "Digita almeno " + n + " caratter" + (n == 1 ? "e" : "i"); },
    formatNoMatches: 'Pazzesco non copre ancora questo CAP'
  })

  const alreadySelectedZip = $("#vars").data('zip')
  if (alreadySelectedZip) {
    $("#input-zip").val(alreadySelectedZip);
    // console.log('Triggering change with', alreadySelectedZip);
  }
  $("#input-zip").trigger('change');
  // setTimeout(function () {
  //   $('#input-zip').select2('open');
  // }, 200);

  // reset date if ZIP unset
  if ($("#input-zip").val() === '') {
    // console.log('resettng date');
    resetAndDisableDateField();
  }
  // Possibly enable date when ZIP or pax are set
  $('#input-zip').on('change', possiblyEnableDateField);
  $('#input-people').on('change', possiblyEnableDateField);

  // Allow form submitting if all parameters are set
  $(document).on('submit', '.pazzesco-reservation .reservation-form', function (e) {
    // alert('sbumitting');
    if ($("#input-zip").val() === '' || $("#input-people").val() === '' || $("#input-date").val() === '') {
      e.preventDefault();
      // alert('missing parameter')
      $('.toast .toast-body').html('<span>Compila i tre campi</span><BR/><span>per favore</span>');
      $('.toast').toast('show');
    }
    // else alert('going on')
  });

  // Fix double-click on mobile
  $('.select2-choice').off("touchstart");

  // Check whether ZIP & PAX are already filled and update date
  possiblyEnableDateField($('#input-zip').val(), $('#input-people').val())

  // Prevent toast from covering part of the GUI (e.g. Start button)
  $('.toast').css('display', 'none');
});