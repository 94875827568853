const { Italian } = require("flatpickr/dist/l10n/it");
const MINIMUM_LEAD_TIME_HOURS = 24;

$(function () {
  // SHOP 
  $('.shop .left-side .filters').click(function () {
    $('.shop .left-side .search').toggleClass('d-block');
    $('.shop .left-side .filters .toggle-handle-up').toggleClass('d-inline');
    $('.shop .left-side .filters .toggle-handle-down').toggleClass('d-inline');
    $('.shop .left-side .filters .toggle-handle-down').toggleClass('d-none');
  })


  // CART
  // Custom variant selection
  $('.shopdetail-tabs .nav-link').click(function () {
    radio_id = "#" + $(this).data('radio');
    $(radio_id).click();
  })

  // CART ITEM REMOVAL
  $('#update-cart .cart-item-delete a').unbind('click');
  $('#update-cart .cart-item-delete a').click(function (e) {
    console.log('$qty_id---------------------', $qty_id);
    e.preventDefault();
    // Set qty t0 0
    var $qty_id = '.input-quantity' + $(this).data('item-id');
    console.log('$qty_id', $qty_id);
    $($qty_id).val(0)
    // Submit
    $('#update-cart').submit();
  })

  // CART ITEM QUANTITY INCREASE
  $('#update-cart .cart-item-quantity .qty-modifier').click(function () {
    var update_button_html = '<button name="button" type="submit" class="btn btn-theme-alt btn-sm">Aggiorna</button>';
    $(this).closest('tr').find('.cart-item-total').html(update_button_html);
    $('#cart-detail .subtotal-amount').html(update_button_html);
    $('#cart-detail .total-amount').html(update_button_html);
  })

  // ORDER HOUR SELECTION
  $("#order_hour").flatpickr({
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true,
    // defaultDate: "13:00",
    "locale": Italian // locale for this instance only
  });

  // PAYMENT INFO SELECTION
  $('#payment-tabs label').on('click', function () {
    $('#payment-tabs label').removeClass('active');
    $(this).addClass('active');
  })

  // VARIANTS AND SUGGESTED MATCHES 
  // Non-interrupting Add button
  $('.hoverbox a, .add-to-cart').on('click', function (e) {
    e.preventDefault();
    $.ajax({
      method: 'POST',
      url: $(this).attr('href'),
      data: {
        variant_id: $(this).data('variant'),
        quantity: 1
      }
    }).done(function (data) {
      $('.toast .toast-body').text('Aggiunto al carrello!');
      $('.toast').toast('show');
      $('.cart-info').html(data.fragment);
    }).fail(function () {
      $('.toast .toast-body').text('Non riesco, mi spiace');
      $('.toast').toast('show');
    })
  })
});